
import { Component, Prop, Vue } from "vue-property-decorator";
import { CalendarEvent } from "vuetify";
import moment from "moment";

import type { Tile } from "../../tile/store";

@Component({
  components: {
    News: () => import("@/app/modules/news/components/NewsComponent.vue")
  },
  filters: {
    date(value, appLanguage) {
      if (!value) return;

      return moment(value)
        .locale(appLanguage)
        .format("Do MMMM, Y");
    }
  }
})
export default class SchedulePage extends Vue {
  @Prop(Object) readonly tile!: Tile;

  type = "week";

  typeToLabel = {
    month: this.$t("EREPORTER.SCHEDULE.MONTH_VIEW"),
    week: this.$t("EREPORTER.SCHEDULE.WEEK_VIEW"),
    day: this.$t("EREPORTER.SCHEDULE.DAY_VIEW"),
    "4day": this.$t("EREPORTER.SCHEDULE.FOUR_DAY_VIEW")
  };

  weekday: number[] = [1, 2, 3, 4, 5, 6, 0];

  selectedEvent = {};
  selectedElement = null;
  selectedOpen = false;

  value: string = moment().format("YYYY-MM-DD");
  events: CalendarEvent[] = [];

  get userData() {
    return this.$store.getters["profile/data"];
  }

  get appLanguage() {
    return this.$store.getters["app/language"];
  }

  get intervalCount() {
    const _ = this.tile.contentable;
    const differenceInHours = moment
      .duration(moment(_.time_to, "HH:mm").diff(moment(_.time_from, "HH:mm")))
      .asHours();

    return Math.ceil(differenceInHours);
  }

  get isAvailableBookingApp() {
    return this.tile.contentable.is_available_booking_app;
  }

  get unavailable() {
    const massMarket = this.tile.contentable.mass_market;
    return (!massMarket && !this.hasRole("inhabitant")) || this.hasRole("guest");
  }

  getEvents() {
    const events: CalendarEvent[] = [];

    this.$store
      .dispatch("booking/fetchResourceSchedule", {
        id: this.tile.contentable_id,
        date_from: (this.$refs.calendar as CalendarEvent).lastStart.date
      })
      .then(({ data }) => {
        data.data.map(event =>
          events.push({
            name: event.resource.title,
            start: moment(`${event.date_from} ${event.time_from}`).toDate(),
            end: moment(`${event.date_to} ${event.time_to}`).toDate(),
            color: event.status?.color ?? '#ff0000',
            timed: !event.is_all_day,

            date_from: event.date_from,
            date_to: event.date_to,
            time_from: event.time_from,
            time_to: event.time_to
          })
        );
      })
      .catch(error => {
        console.log(error);
      });

    this.events = events;
  }

  showDate() {
    this.type = "day";
  }

  showEvent({ nativeEvent, event }) {
    const open = () => {
      this.selectedEvent = event;
      this.selectedElement = nativeEvent.target;
      requestAnimationFrame(() => requestAnimationFrame(() => (this.selectedOpen = true)));
    };

    if (this.selectedOpen) {
      this.selectedOpen = false;
      requestAnimationFrame(() => requestAnimationFrame(() => open()));
    } else {
      open();
    }

    nativeEvent.stopPropagation();
  }

  showTime(event) {
    if (!this.isAvailableBookingApp || this.unavailable) return;

    const { date, hour } = event;

    this.$router.push({
      name: "booking.reservations.create",
      query: { date, hour }
    });
  }

  hasRole(role: string): boolean {
    return this.userData.roles.some(r => r.slug == role);
  }
}
